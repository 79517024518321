import { Injectable, inject } from '@angular/core';
import { Auth, signInWithPopup, user } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { GoogleAuthProvider } from 'firebase/auth';
import { firstValueFrom, from, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import debug from '@utils/debug';

const log = debug('services:auth');

export interface Claims {
  admin?: boolean;
  coach?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router = inject(Router);
  private fireAuth = inject(Auth);

  /**
   * Current user from Firebase Auth.
   */
  readonly user$ = user(this.fireAuth).pipe(shareReplay({ refCount: false }));

  /**
   * Current user Id.
   */
  readonly userId$ = this.user$.pipe(
    map((user) => user?.uid),
    distinctUntilChanged()
  );

  /**
   * Returns true when user is logged in.
   */
  readonly isLoggedIn$ = this.userId$.pipe(map((userId) => !!userId));

  readonly claims$ = this.user$.pipe(
    switchMap((user) =>
      user
        ? from(user.getIdTokenResult()).pipe(
            map(({ claims }) => claims as Claims)
          )
        : of({} as Claims)
    )
  );

  async signIn() {
    return firstValueFrom(this.loginWithGoogle());
  }

  async signOut() {
    await this.router.navigate(['sign-in']);
    log('signOut');
    await this.fireAuth.signOut();
  }

  private loginWithGoogle() {
    log('loginWithGoogle');
    return from(signInWithPopup(this.fireAuth, new GoogleAuthProvider()));
  }
}
