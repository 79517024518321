import * as Sentry from '@sentry/angular';
import { environment } from '@env';
import { GIT_SHA, VERSION } from '../version';

function isEnabled() {
  return (
    !environment.useEmulators &&
    !['localhost', '127.0.0.1'].includes(window.location.hostname)
  );
}

export function initSentry() {
  Sentry.init({
    dsn: 'https://6ec210947f359c72844e8550e358e7b1@o4504690115870720.ingest.us.sentry.io/4508132594155520',
    enabled: isEnabled(),
    environment: environment.name,
    release: GIT_SHA ? `${VERSION}--${GIT_SHA}` : VERSION,
    dist: environment.name,
  });
}
