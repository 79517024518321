import { Routes } from '@angular/router';
import { NotFoundPageComponent } from './pages/not-found/NotFoundComponent';
import { SignInPageComponent } from './pages/sign-in/sign-in.component';

export const appRoutes: Routes = [
  { path: 'sign-in', component: SignInPageComponent },
  {
    path: 'report',
    loadChildren: () => import('@report-engine/report-engine.routes'),
  },
  // { path: 'error', component: ErrorComponent },
  // must be last!
  { path: '**', component: NotFoundPageComponent },
];
